import { Route, RoutePoint, RoutePointWithAdditionalInfo } from '@npaShared/models/route/route.models';
import { RoutePhaseTypes } from 'src/app/npa/document-agreement/enums/route-phase-types.enum';
import { DocumentsControlAlternatives } from '@npaShared/models/document/documents-control-alternatives.model';
import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { nextPointOrCurrentPointIsLeader } from './point-helper';
import { getSubPhasesBelongingToIterationOfPoint, isReworkOnProjectCreatedPreviousSubPhase } from './subphase-helper';

/**
 * нужна ли блокировка кнопок в зависимости от наличия/отсутствия замечаний
 *
 * @param approveType тип согласования
 * @param route маршрут
 * @param selectedPoint текущая точка
 * @param remarks список с замечаниями/альтернативами
 * @returns
 */
export const needDisabledButtonToDependingRemarks = (
  approveType: ApproveTypeEnum,
  route: Route,
  selectedPoint: RoutePoint | RoutePointWithAdditionalInfo,
  hasUnprocessedRemarks: boolean,
  remarks: DocumentsControlAlternatives[],
): boolean => {
  switch (approveType) {
    case ApproveTypeEnum.approve:
      return needDisabledButtonApprove(route, selectedPoint, hasUnprocessedRemarks, remarks);
    case ApproveTypeEnum.startApprovement:
      return needDisabledButtonApprove(route, selectedPoint, hasUnprocessedRemarks, remarks);

    case ApproveTypeEnum.approveLuz:
      return needDisabledButtonApproveLuz(route, selectedPoint, remarks);
    case ApproveTypeEnum.startApprovementWithRemarks:
      return needDisabledButtonApproveLuz(route, selectedPoint, remarks);

    case ApproveTypeEnum.startOuterApprovement:
      return needDisabledButtonStartOuterApprovement(route, selectedPoint, hasUnprocessedRemarks);

    default:
      return false;
  }
};

/**
 * нужна ли блокировка кнопок "Согласовать" и "Отправить на согласование" в зависимости от наличия/отсутствия замечаний
 *
 * @param route маршрут
 * @param point текущая точка
 * @param remarks список с замечаниями/альтернативами
 * @returns
 */
const needDisabledButtonApprove = (
  route: Route,
  point: RoutePoint | RoutePointWithAdditionalInfo,
  hasUnprocessedRemarks: boolean,
  remarks: DocumentsControlAlternatives[],
): boolean => {
  const hasRemarks = Boolean(remarks?.length);

  const roles = [EmployeeRouteTypes.coordinator];
  if (roles.includes(point.pointRoleId)) {
    if (!hasUnprocessedRemarks) {
      return needDisableDecideButtons({ route, point }) && hasRemarks;
    }

    const isProjectCreatedReworkOnPreviousSubPhase = isReworkOnProjectCreatedPreviousSubPhase(route, point.id);
    return needDisableDecideButtons({
      route,
      point,
      isReworkOnProjectCreatedPreviousSubPhase: isProjectCreatedReworkOnPreviousSubPhase,
    });
  }

  return needDisableDecideButtons({ route, point }) && hasRemarks;
};

/**
 * нужна ли блокировка кнопок "Согласовать с замечаниями" и "Отправить на согласование с замечаниями"
 * в зависимости от наличия/отсутствия замечаний
 *
 * @param route маршрут
 * @param point текущая точка
 * @param remarks список с замечаниями/альтернативами
 * @returns
 */
const needDisabledButtonApproveLuz = (
  route: Route,
  point: RoutePoint | RoutePointWithAdditionalInfo,
  remarks: DocumentsControlAlternatives[],
): boolean => needDisableDecideButtons({ route, point }) && !Boolean(remarks?.length);

const needDisabledButtonStartOuterApprovement = (
  route: Route,
  point: RoutePoint | RoutePointWithAdditionalInfo,
  hasUnprocessedRemarks: boolean,
): boolean => {
  if (!hasUnprocessedRemarks) {
    return false;
  }

  const hasPointOnRework = point.phaseTypeId === RoutePhaseTypes.projectCreatedRework;
  const subPhases = getSubPhasesBelongingToIterationOfPoint(route, point.id);
  const onlyReworkOnIteration = subPhases.length === 1;

  if (point.pointRoleId === EmployeeRouteTypes.author && hasPointOnRework && onlyReworkOnIteration) {
    return false;
  }

  const isNextPointLeader = nextPointOrCurrentPointIsLeader(route, point);
  const projectCreatedReworkIsPreviousSubPhase = isReworkOnProjectCreatedPreviousSubPhase(route, point.id);
  return (projectCreatedReworkIsPreviousSubPhase || hasPointOnRework) && isNextPointLeader;
};

/** нужна ли блокировка кнопок:
 * "Согласовать", "Согласовать с замечаниями",
 * "Отправить на согласование", "Отправить на согласование с замечаниями"
 */
const needDisableDecideButtons = (params: {
  route: Route;
  point: RoutePoint;
  isReworkOnProjectCreatedPreviousSubPhase?: boolean;
}): boolean => {
  /** Этапы, на которых не нужна блокировка кнопок в зависимости от наличия/отсутствия замечаний */
  const phasesNotBlockingButtons = [
    RoutePhaseTypes.projectCreated,
    RoutePhaseTypes.projectCreatedApprovement,
    RoutePhaseTypes.projectCreatedDevelopment,
    RoutePhaseTypes.projectCreatedInitiation,
    RoutePhaseTypes.projectCreatedRework,
    RoutePhaseTypes.preliminaryApprovement,
    RoutePhaseTypes.preliminaryApprovementApprovement,
    RoutePhaseTypes.preliminaryApprovementInitiation,
    RoutePhaseTypes.preliminaryApprovementRevision,
    RoutePhaseTypes.oauRegistration,
    RoutePhaseTypes.oauRegistrationApprovement,
    RoutePhaseTypes.oauRegistrationInitiation,
  ];

  const isOnMainVertical = params.point.level === 1;
  const isDisabledButtonsPhase = !phasesNotBlockingButtons.includes(params.point.phaseTypeId);
  const isNextPointLeader = nextPointOrCurrentPointIsLeader(params.route, params.point);

  if (params.isReworkOnProjectCreatedPreviousSubPhase) {
    return isOnMainVertical && isNextPointLeader;
  }

  return isOnMainVertical && isDisabledButtonsPhase && isNextPointLeader;
};
