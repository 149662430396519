import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { DocumentProhibitionStatus } from '@npaShared/enums/document-prohibition-status.enum';
import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';
import { scrollDown, scrollUp } from '@shared/functions/scrolling.function';
import { Store } from '@ngxs/store';
import { SetActiveDocument, SetDocuments } from '@store/documents/documents.action';
import { DocumentsState } from '@store/documents/documents.state';

/** Сервис используется для организации работы с ПДФ-просмотрщиком
 *
 * Сервис выносит в себя информацию о списке документов и текущей активной странице,
 * чтобы было удобно синхронизировать работу из нескольких компонентов
 *
 * Сервис не является синглтоном - он объявляется в провайдерах компонента и его экземпляр доступен потомкам
 */
@Injectable()
export class DocumentPackageViewerService {
  /** ПДФ готов к просмотру и взаимодействию только после того, как будет получен с сервера */
  private isReady$ = new BehaviorSubject<boolean>(false);
  private viewer: Element;

  constructor(private store: Store) {}

  public setViewer(viewer: Element): void {
    this.viewer = viewer;
  }

  public getDocuments$(): Observable<DocumentInfoResponseModel[]> {
    return this.store.select(DocumentsState.documents);
  }

  public getActiveDocument$(): Observable<DocumentInfoResponseModel | null> {
    return this.store
      .select(DocumentsState.activeDocument)
      .pipe(distinctUntilChanged((prev, current) => prev?.id === current?.id));
  }

  public getActiveDocument(): DocumentInfoResponseModel | null {
    return this.store.selectSnapshot(DocumentsState.activeDocument);
  }

  public setActiveDocument(document: DocumentInfoResponseModel): void {
    this.store.dispatch(new SetActiveDocument(document));
  }

  public scrollDocDown(): void {
    scrollDown(this.viewer);
  }

  public scrollDocUp(): void {
    scrollUp(this.viewer);
  }

  public getIsReadyState$(): Observable<boolean> {
    return this.isReady$.asObservable();
  }

  public setIsReadyState(isReady: boolean): void {
    this.isReady$.next(isReady);
  }

  /** Установить список доступных для просмотра пользователю документов */
  public setDocumentsList(documents: DocumentInfoResponseModel[]): void {
    this.store.dispatch(new SetDocuments(documents));
  }

  /** Получение предшествующего текущему документа, который доступен пользователю */
  public getPrevAccessibleDocument$(): Observable<DocumentInfoResponseModel | undefined> {
    return combineLatest([this.getActiveDocument$(), this.getDocuments$()]).pipe(
      map(([doc, table]) => this.getPrevAccessibleDoc(doc, table)),
    );
  }

  /** Получение следующего за текущим документа, который доступен пользователю */
  public getNextAccessibleDocument$(): Observable<DocumentInfoResponseModel | undefined> {
    return combineLatest([this.getActiveDocument$(), this.getDocuments$()]).pipe(
      map(([doc, table]) => this.getNextAccessibleDoc(doc, table)),
    );
  }

  /** Сбросит состояние сервиса до изначального */
  public toInitialState(): void {
    this.setDocumentsList([]);
    this.setIsReadyState(false);
  }

  private getPrevAccessibleDoc(
    currentDoc: DocumentInfoResponseModel,
    documents: DocumentInfoResponseModel[],
  ): DocumentInfoResponseModel | undefined {
    if (!currentDoc) {
      return;
    }

    if (!documents?.length) {
      return;
    }

    const currentDocIdx = documents.findIndex((doc) => doc.id === currentDoc.id);

    /** если документ первый в списке, то надо открыть первый доступный документ с конца списка */
    if (currentDocIdx === 0) {
      const lastDoc = documents[documents.length - 1];
      if (lastDoc?.prohibitionStatus === DocumentProhibitionStatus.CLOSED) {
        return this.getPrevAccessibleDoc(lastDoc, documents);
      }
      return lastDoc;
    }

    const prevDoc = documents[currentDocIdx - 1];
    if (prevDoc?.prohibitionStatus === DocumentProhibitionStatus.CLOSED) {
      return this.getPrevAccessibleDoc(prevDoc, documents);
    }

    return prevDoc;
  }

  private getNextAccessibleDoc(
    currentDoc: DocumentInfoResponseModel,
    documents: DocumentInfoResponseModel[],
  ): DocumentInfoResponseModel | undefined {
    if (!currentDoc) {
      return;
    }

    if (!documents?.length) {
      return;
    }

    const currentDocIdx = documents.findIndex((doc) => doc.id === currentDoc.id);

    /** если документ последний в списке, то надо открыть первый доступный из списка */
    if (currentDocIdx === documents.length - 1) {
      const firstDoc = documents[0];
      if (firstDoc.prohibitionStatus === DocumentProhibitionStatus.CLOSED) {
        return this.getNextAccessibleDoc(firstDoc, documents);
      }
      return firstDoc;
    }

    const nextDoc = documents[currentDocIdx + 1];
    if (nextDoc.prohibitionStatus === DocumentProhibitionStatus.CLOSED) {
      return this.getNextAccessibleDoc(nextDoc, documents);
    }

    return nextDoc;
  }
}
