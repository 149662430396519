import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';
import { SigningVersionModel } from '@npaShared/models/document/documents-revision-info.model';

export class SetDocuments {
  public static readonly type = '[Documents] Set Documents';
  constructor(public documents: DocumentInfoResponseModel[]) {}
}

export class SetSigningVersions {
  public static readonly type = '[Documents] Set Documents Signing Versions';
  constructor(public documentSigningVersions: SigningVersionModel[]) {}
}

export class SetRemarksSheetSigningVersions {
  public static readonly type = '[Documents] Set Remarks Sheet Signing Versions';
  constructor(public remarksSheetSigningVersions: SigningVersionModel[]) {}
}

export class SetActiveDocument {
  public static readonly type = '[Documents] Set Active Document';
  constructor(public document: DocumentInfoResponseModel) {}
}

export class SetActualPhaseIdForActivePoint {
  public static readonly type = '[Documents] Set Actual Phase Id For Active Point';
  constructor(public phaseId: number) {}
}

export class UpdateActiveVersion {
  public static readonly type = '[Documents] Update Active Version';
}

export class ChangeRevisionFromSocket {
  public static readonly type = '[Documents] Change Revision From Socket';
  constructor(public signingVersion: SigningVersionModel) {}
}

export class ResetDocumentsState {
  public static readonly type = '[Documents] Reset Documents State';
}
