import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalErrorComponent } from '@components/modal-error/modal-error.component';
import { LoadingController, ModalController } from '@ionic/angular';
import { CertificateItemModel } from '@models/esigns/certificate-item.model';
import { Store } from '@ngxs/store';
import { DocumentPackageApiService } from '@npaApi/document-package-info.api.service';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { ApproveTypeEnum } from '@npaShared/enums/approve-type.enum';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { SigningEnum } from '@npaShared/enums/signing.enum';
import { getParamsModalConfirmation } from '@npaShared/functions/get-params-modal-confirmation';
import { DocumentPackageApproveModel } from '@npaShared/models/document-package/document-package-approve.model';
import { DocumentPackageStoreModel } from '@npaShared/models/document-package/document-package-store.model';
import { DocumentRevisionModel } from '@npaShared/models/document/document-revision.model';
import { EmployeeBaseModel } from '@npaShared/models/employee/employee-base.model';
import { ModalReturnConfirmationModel } from '@npaShared/models/modal/modal-return-confirmation.model';
import { Route, RoutePoint, RoutePointWithAdditionalInfo } from '@npaShared/models/route/route.models';
import { AgreementNavigateService } from '@npaShared/services/agreement-navigate.service';
import { ActiveCategoryState } from '@npaShared/store/active-category/active-category.state';
import { logToSentry } from '@shared/functions/set-users-for-sentry.function';
import { FileCertificateHelperService } from '@shared/services/file-certificate-helper.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';
import { AgreementService } from 'src/app/npa/document-agreement/services/agreement.service';
import { getMultipleActivePointLogic } from 'src/app/npa/document-package/logic/multiple-active-points';
import { SelectedRoutePointService } from 'src/app/npa/document-package/services/selected-route-point.service';
import { DocumentApiService } from '@npaApi/document.api.service';
import { getPhaseByPointId } from '@npaShared/helpers/route/phases-helper';
import { isLegalActDocumentPackage } from '@npaShared/functions/project-type-document-package.functions';

import { SigningVersionModel } from '@npaShared/models/document/documents-revision-info.model';
import { getDocumentRevisionsForSignature } from '@npaShared/helpers/document-revision';
import { getPointByIdWithAdditionalInfo } from '@npaShared/helpers/route/point-helper';
import { DocumentsState } from '@store/documents/documents.state';
import { AgreementFactory } from './logic-agreements/agreement-factory';
import { SignFactory } from './logic-signing/sign-factory';
import { SigningValidationHelperService } from './signing-validation-helper.service';

@Injectable({
  providedIn: 'root',
})
export class AgreementSignService {
  private sign: SigningEnum = null;
  private certificate: CertificateItemModel = null;
  private modalOrLoader: HTMLIonModalElement | HTMLIonLoadingElement | null = null;
  private submitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private userStore: CurrentUserAissdStoreService,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private agreementHelper: AgreementService,
    private fileCertificateHelper: FileCertificateHelperService,
    private agreementNavigate: AgreementNavigateService,
    private signingValidationHelper: SigningValidationHelperService,
    private store: Store,
    private documentPackageApi: DocumentPackageApiService,
    private selectedActivePointService: SelectedRoutePointService,
    private documentApi: DocumentApiService,
  ) {}

  public get hasCloudEsign(): boolean {
    return this.userStore.getCurrentUserSnapshot()?.isAssignCryptoproDss;
  }

  public get isSubmitting(): Observable<boolean> {
    return this.submitting$.asObservable();
  }

  /** @returns true если есть категория подписанта */
  public hasSignerCategory(activePoint: RoutePointWithAdditionalInfo): boolean {
    const hasSignerCategories = activePoint?.signerCategories?.length > 0;
    const hasSomeLocalAgreementSheet =
      Boolean(activePoint?.agreementSheetProjectAgreed) || Boolean(activePoint?.agreementSheetProjectPresented);
    const isInvestmentProject = Boolean(activePoint?.isInvestmentProject);

    return hasSignerCategories || hasSomeLocalAgreementSheet || isInvestmentProject;
  }

  /** @returns true если есть категория подписанта или это точка исполнителя/руководителя */
  public hasSignerCategoryOrNeededRoles(activePoint: RoutePoint): boolean {
    const hasSignerCategories = activePoint?.signerCategories?.length > 0;
    const isLeaderOrExecutor = [EmployeeRouteTypes.leader, EmployeeRouteTypes.executor].includes(
      activePoint?.pointRoleId,
    );
    const hasSomeLocalAgreementSheet =
      Boolean(activePoint?.agreementSheetProjectAgreed) || Boolean(activePoint?.agreementSheetProjectPresented);
    const isInvestmentProject = Boolean(activePoint?.isInvestmentProject);

    return isLeaderOrExecutor ? true : hasSignerCategories || hasSomeLocalAgreementSheet || isInvestmentProject;
  }

  public setSign(sign: CertificateItemModel | null): void {
    if (!sign) {
      this.sign = SigningEnum.withoutSign;
      return;
    }

    this.certificate = sign;
    this.sign = sign.isCloud ? SigningEnum.cloud : SigningEnum.local;
  }

  public isValidSignatures(): boolean {
    const isSigningWithCertificate = [SigningEnum.cloud, SigningEnum.local].includes(this.sign);

    if (isSigningWithCertificate && !this.signingValidationHelper.isValidCertificate(this.certificate)) {
      return false;
    }

    return true;
  }

  public showInvalidCertificateError(): void {
    this.signingValidationHelper.showModalInvalidCertificate(this.certificate);
  }

  public async signDocumentPackage(
    type: ApproveTypeEnum,
    preparationParams: ParamsForPreparationSigning,
  ): Promise<void> {
    this.submitting$.next(true);

    const paramsSigning = preparationParams.paramsSigning;

    const signableDocs = await this.documentApi
      .getSignableByDocumentPackageAndActivePointId(preparationParams.documentPackage.id, paramsSigning.pointId)
      .toPromise();

    const signableVersions =
      this.store.selectSnapshot(
        DocumentsState.allSigningVersionsIfNeedToUseVersionForSigning(
          preparationParams.documentPackage.route,
          preparationParams.paramsSigning.pointId,
        ),
      ) || [];

    /** список ревизий, которые надо подписывать */
    const revisionsForSign = this.getRevisionsForSign(
      preparationParams.documentPackage,
      paramsSigning.pointId,
      signableDocs,
      signableVersions,
      preparationParams.documentPackage.route,
    );

    const paramsForRequest = await this.getParamsForRequest({
      ...paramsSigning,
      revisions: revisionsForSign,
    }).catch((err) => {
      this.submitting$.next(false);
      console.error(err);
      return err;
    });

    if (paramsForRequest instanceof Error) {
      return;
    }

    await this.showModalOrLoader();

    this.getRequest(type, preparationParams, paramsForRequest)
      .pipe(
        first(),
        finalize(() => this.submitting$.next(false)),
      )
      .subscribe(
        (updatedRoute) => {
          const activeCategory = this.store.selectSnapshot(ActiveCategoryState.getActiveCategory);
          const logic = getMultipleActivePointLogic(
            activeCategory,
            updatedRoute,
            this.userStore.getCurrentUserSnapshot(),
          );
          const activePoints = logic.getActivePointsList();
          this.selectedActivePointService.setPotentialActivePointsList(activePoints);

          const agreementLogic = AgreementFactory(type, this.agreementHelper, this.store, this.documentPackageApi);
          const dataForModalConfirmation = agreementLogic.getParamsForModalConfirmation(
            preparationParams.documentPackage,
            preparationParams.paramsSigning.text,
            preparationParams.author,
          );

          this.showInfoPopup(dataForModalConfirmation);
        },
        (error: HttpErrorResponse) => {
          if (type === ApproveTypeEnum.approve) {
            this.approveErrorHandler(error);
          }
        },
      );
  }

  private getRequest(
    type: ApproveTypeEnum,
    preparationParams: ParamsForPreparationSigning,
    paramsForRequest: DocumentPackageApproveModel,
  ): Observable<Route> {
    const agreementLogic = AgreementFactory(type, this.agreementHelper, this.store, this.documentPackageApi);
    return agreementLogic.getRequest(preparationParams, paramsForRequest).pipe(
      finalize(() => this.modalOrLoader.dismiss()),
      catchError((error) => {
        logToSentry(choosingAgreementTitle.get(type) + choosingSigningTitle.get(this.sign), {
          paramsForRequest,
          error,
        });
        throw error;
      }),
    );
  }

  private async showInfoPopup(data: ModalReturnConfirmationModel): Promise<void> {
    const infoPopup = await this.modalController.create({
      ...getParamsModalConfirmation(),
      componentProps: { data },
    });

    infoPopup.present();

    await infoPopup.onDidDismiss();
    this.agreementNavigate.redirectAfterDecision();
  }

  private async getParamsForRequest(data: ParamsForSigning): Promise<DocumentPackageApproveModel> {
    const signingLogic = SignFactory(
      this.sign,
      this.agreementHelper,
      this.modalController,
      this.loadingController,
      this.fileCertificateHelper,
      this.userStore,
    );
    return await signingLogic.getParams(data);
  }

  private async showModalOrLoader(): Promise<void> {
    const logicSign = SignFactory(
      this.sign,
      this.agreementHelper,
      this.modalController,
      this.loadingController,
      this.fileCertificateHelper,
      this.userStore,
    );

    this.modalOrLoader = await logicSign.getModalOrLoading(this.hasCloudEsign);
  }

  private async showErrorMessage(error: HttpErrorResponse): Promise<void> {
    const modal = await this.modalController.create({
      component: ModalErrorComponent,
      showBackdrop: true,
      cssClass: 'error-modal',
      backdropDismiss: false,
      componentProps: {
        title: 'Выполнить действие невозможно',
        label: error?.error?.message,
        buttonLabel: 'обновить',
      },
    });

    await modal.present();
    await modal.onWillDismiss();

    this.agreementNavigate.onUpdateSelectedDocumentAndGoBack();
  }

  private approveErrorHandler(error: HttpErrorResponse): void {
    if (error?.status === HttpStatusCode.NotAcceptable) {
      this.showErrorMessage(error);
      return;
    }
    throw error;
  }

  /**
   * Получить список ревизий, которые надо подписывать
   *
   * @param documentPackage
   * @param selectedPointId
   * @param signableDocs документы, которые надо подписанить
   * @param signingVersions версии документов для подписания
   * @returns
   */
  private getRevisionsForSign(
    documentPackage: DocumentPackageStoreModel,
    selectedPointId: number,
    signableDocs: number[],
    signingVersions: SigningVersionModel[],
    route: Route,
  ): DocumentRevisionModel[] {
    let revisionsForSign = [];

    const selectedPoint = getPointByIdWithAdditionalInfo(route, selectedPointId);
    revisionsForSign = getDocumentRevisionsForSignature(documentPackage.documents, signingVersions, selectedPoint);

    if (signableDocs.length) {
      revisionsForSign = revisionsForSign.filter((r) => signableDocs.includes(r.documentId));
    }

    const isNpaProjectGroupType = isLegalActDocumentPackage(documentPackage.documentPackage);
    if (isNpaProjectGroupType) {
      return revisionsForSign;
    }

    const phase = getPhaseByPointId(documentPackage.route, selectedPointId);
    /** у пользовательких этапов есть настройки */
    const phaseSettings = phase.phaseTemplate;

    if (phaseSettings) {
      const documents = documentPackage.documents
        .filter((r) => phaseSettings.signableDocumentTypes.find((doc) => doc.id === r.documentType.id))
        .map((d) => d.id);

      revisionsForSign = revisionsForSign.filter((r) => documents.includes(r.documentId));
    }

    return revisionsForSign;
  }
}

const choosingAgreementTitle = new Map<ApproveTypeEnum, string>([
  [ApproveTypeEnum.approve, 'Согласование'],
  [ApproveTypeEnum.approveLuz, 'Согласование с замечаниями'],
  [ApproveTypeEnum.sendRemarksToAuthor, 'Направить замечания без согласования'],
]);

const choosingSigningTitle = new Map<SigningEnum, string>([
  [SigningEnum.withoutSign, 'без подписи'],
  [SigningEnum.cloud, 'с облачной подписью'],
  [SigningEnum.local, 'с локальной подписью'],
]);

/** параметры для подготовки запроса */
export interface ParamsForPreparationSigning {
  paramsSigning: ParamsForSigning;
  documentPackage: DocumentPackageStoreModel;
  author: EmployeeBaseModel;
}

export interface ParamsForSigning {
  pointId: number;
  text: string;
  revisions?: DocumentRevisionModel[];
  /** для облачного */
  certificateId: number;
}
