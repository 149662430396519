import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  HideAuthenticationModerationForm,
  ShowAuthenticationModerationForm,
} from './authentication-information-store.actions';

interface AuthenticationInformationModel {
  showAuthFormDuringModeration: boolean;
}

@Injectable()
@State<AuthenticationInformationModel>({
  name: 'authenticationInformationState',
  defaults: { showAuthFormDuringModeration: false },
})
export class AuthenticationInformationState {
  @Selector()
  public static showAuthFormDuringModeration(state: AuthenticationInformationModel): boolean {
    return state.showAuthFormDuringModeration;
  }

  @Action(ShowAuthenticationModerationForm)
  public showAuthenticationModerationForm({ patchState }: StateContext<AuthenticationInformationModel>): void {
    patchState({ showAuthFormDuringModeration: true });
  }

  @Action(HideAuthenticationModerationForm)
  public hideAuthenticationModerationForm({ patchState }: StateContext<AuthenticationInformationModel>): void {
    patchState({ showAuthFormDuringModeration: false });
  }
}
