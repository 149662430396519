import { Route } from '@npaShared/models/route/route.models';

export class SetRoute {
  public static readonly type = '[Route] Set route';
  constructor(public route: Route) {}
}

export class ResetRoute {
  public static readonly type = '[Route] Reset route';
}
