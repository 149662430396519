import { LoadingController, ModalController } from '@ionic/angular';
import { CryptoSignatureTypeEnum } from '@npaShared/enums/crypto-signature-type.enum';
import {
  DocumentPackageApproveModel,
  DocumentSignature,
} from '@npaShared/models/document-package/document-package-approve.model';
import { DocumentRevisionModel } from '@npaShared/models/document/document-revision.model';
import { FileCertificateHelperService } from '@shared/services/file-certificate-helper.service';
import { ParamsForSigning } from '../agreement-sign.service';
import { AgreementService } from '../../../../document-agreement/services/agreement.service';
import { MockSigning } from '../../../../document-agreement/const/mock-signing.const';
import { BaseSign } from './base-signing';

export class LocalSigning extends BaseSign {
  private revisions: DocumentRevisionModel[] = [];

  constructor(
    protected loadingController: LoadingController,
    protected modalController: ModalController,
    protected agreementService: AgreementService,
    protected fileCertificateHelper: FileCertificateHelperService,
  ) {
    super(loadingController, modalController);
  }

  public async getModalOrLoading(): Promise<HTMLIonLoadingElement | HTMLIonModalElement> {
    return await this.createApproveLoader();
  }

  public async getParams(data: ParamsForSigning): Promise<DocumentPackageApproveModel> {
    this.revisions = data.revisions;

    const { documentSignaturesList, signature } = await this.prepareData();

    const signatureParams = {
      signatureType: CryptoSignatureTypeEnum.clientSide,
      documentPackageSignature: {
        packageMetadata: this.getPackageMetadata(documentSignaturesList),
        signature,
      },
      documentSignaturesList,
    };

    return {
      revisions: data.revisions,
      text: data.text,
      pointId: data.pointId,
      signature: documentSignaturesList.length ? signatureParams : null,
    };
  }

  private getPackageMetadata(documentSignaturesList: DocumentSignature[]): string {
    return JSON.stringify(
      documentSignaturesList.map((d) => ({ ldeRevisionId: d.ldeRevisionId, ldeDocumentId: d.ldeDocumentId })),
    );
  }

  private async prepareData(): Promise<{ documentSignaturesList: DocumentSignature[]; signature: string }> {
    const loader = await this.loadingController.create({
      message: 'Подписание документов',
    });
    loader.present();

    const documentSignaturesList = await this.prepareDataForSign();

    if (!documentSignaturesList) {
      loader.dismiss();
      throw new Error('не подписали какой-то документ');
    }

    const data = this.agreementService.getPackageMetadata(documentSignaturesList);
    let signature = '';

    if ((window as any).isMockSigning) {
      signature = MockSigning;
    } else {
      signature = await this.fileCertificateHelper.writeToFileString(data);
    }

    if (!signature) {
      loader.dismiss();
      throw new Error('не подписали строку с массивом документов');
    }

    loader.dismiss();

    return {
      documentSignaturesList,
      signature,
    };
  }

  private async prepareDataForSign(): Promise<DocumentSignature[] | null> {
    const documentSignaturesList: DocumentSignature[] = [];
    const documentsLength = this.revisions.length;

    for (let index = 0; index < documentsLength; index++) {
      const doc = this.revisions[index];
      const signData = await this.agreementService.signData(doc.ldeId, doc.revision).toPromise();
      let signature = '';

      if ((window as any).isMockSigning) {
        signature = MockSigning;
      } else {
        signature = await this.fileCertificateHelper.writeToFileAndSign(signData.data);
      }

      if (!signature) {
        return null;
      }

      documentSignaturesList.push({
        ldeDocumentId: doc.ldeId,
        ldeRevisionId: doc.revision,
        signature,
      });
    }

    return documentSignaturesList;
  }
}
