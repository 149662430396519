import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Route } from '@npaShared/models/route/route.models';

import { ResetRoute, SetRoute } from './route.action';

@Injectable()
@State<Route | null>({
  name: 'route',
  defaults: null,
})
export class RouteState {
  @Selector()
  public static route(state: Route): Route | null {
    return state;
  }

  @Action(SetRoute)
  public setRoute({ setState }: StateContext<Route | null>, { route }: SetRoute): void {
    setState(route);
  }

  @Action(ResetRoute)
  public resetRoute({ setState }: StateContext<Route | null>): void {
    setState(null);
  }
}
