import { ActualInfoTypeEnum } from '@npaShared/enums/actual-info-type.enum';
import {
  DocumentInfoResponseModel,
  DocumentVersionInfoModel,
} from '@npaShared/models/document/document-info.response.model';
import { SigningVersionModel } from '@npaShared/models/document/documents-revision-info.model';
import { getSigningRevision } from './document-revision';

export const getAllActualVersions = (
  documents: DocumentInfoResponseModel[],
  phaseId: number,
): DocumentVersionInfoModel[] => {
  const versions = documents.map((doc) => getActualDocumentVersion(doc, phaseId)).filter(Boolean);
  return versions;
};

export const needShowDocumentVersionOnPhase = (version: DocumentVersionInfoModel, phaseId: number): boolean =>
  (version.actualInfo.type === ActualInfoTypeEnum.sign && !!version.actualInfo.actual) ||
  (version.actualInfo.type === ActualInfoTypeEnum.list && version.actualInfo.phaseIds.includes(phaseId));

export const getActualDocumentVersion = (
  document: DocumentInfoResponseModel,
  activePointPhaseId: number,
  signableVersions: SigningVersionModel[] = [],
): DocumentVersionInfoModel | undefined => {
  if (!document) {
    return null;
  }

  const actualVersion = document.versions.find((v) => needShowDocumentVersionOnPhase(v, activePointPhaseId));

  if (signableVersions?.length) {
    const signableVersion = signableVersions.find((d) => d.ldeDocumentId === document.ldeDocumentId);
    if (signableVersion) {
      const signingRevision = getSigningRevision(signableVersion);

      return {
        ...actualVersion,
        actualInfo: { type: ActualInfoTypeEnum.sign, actual: true },
        revision: signingRevision,
      };
    }
  }

  return actualVersion;
};
